export const serviceHelper = {
  authHeader,
  isAvailable,
  getEndpoint,
  resourceHeader,
  uploadHeader,
  syncHeader,
  deleteConfig,
  getBaseURL,
  getToken,
  getProjectToken,
  projectTokenHeader,
  getClientId,
};

function getClientId() {
  return "Visoplan Plugin Archicad";
}

function getToken() {
  return JSON.parse(localStorage.getItem("token"));
}

function getProjectToken() {
  return JSON.parse(localStorage.getItem("projectToken"));
}

function authHeader() {
  let token = getToken();
  if (token && token.bearer) {
    const contentLanguage = window.isGerman ? "de-DE" : "en-US";
    return {
      headers: {
        Authorization: `bearer ${token.bearer}`,
        "Content-Language": contentLanguage,
      },
    };
  } else {
    return {};
  }
}

function projectTokenHeader() {
  let token = getProjectToken();
  if (token && token.bearer) {
    const contentLanguage = window.isGerman ? "de-DE" : "en-US";
    return {
      headers: {
        "X-Project-Authorization": `bearer ${token.bearer}`,
        "Content-Language": contentLanguage,
      },
    };
  } else {
    return {};
  }
}

function isAvailable() {
  let token = getToken();
  if (token && token.expires && token.remember) {
    if (token.remember === true) {
      const expireTime = Date.parse(token.expires);
      return Date.now() < expireTime;
    }
  }

  return false;
}

function getEndpoint(baseUrl, ids) {
  let endPoint = ids
    .map((id) => {
      return id;
    })
    .join(",");

  endPoint = `${getBaseURL()}${baseUrl}/${endPoint}`;
  return endPoint;
}

function resourceHeader() {
  let token = getProjectToken();
  if (token && token.bearer) {
    return {
      headers: {
        Accept: "application/octet-stream",
        "X-Project-Authorization": `bearer ${token.bearer}`,
      },
      responseType: "arraybuffer",
    };
  } else {
    return {};
  }
}

function uploadHeader() {
  let token = getProjectToken();
  if (token && token.bearer) {
    return {
      headers: {
        "X-Project-Authorization": `bearer ${token.bearer}`,
        ContentType: "multipart/form-data",
      },
    };
  } else {
    return {};
  }
}

function syncHeader() {
  let token = getProjectToken();
  if (token && token.bearer) {
    return {
      headers: { "X-Project-Authorization": `bearer ${token.bearer}` },
      responseType: "stream",
    };
  } else {
    return {};
  }
}

function deleteConfig(ids) {
  let token = getProjectToken();
  if (token && token.bearer) {
    return {
      headers: { "X-Project-Authorization": `bearer ${token.bearer}` },
      data: ids,
    };
  } else {
    return {};
  }
}

function getBaseURL() {
  return window.baseURL === undefined
    ? "https://prerelease-api.visoplan.de/api/"
    : window.baseURL;
}
